/*
* @About gongw created by 2021/2/1
*/
import request from '@/lib/utils/request'

// 添加项目标段列表
export function addInfo (params) {
  return request({
    url: '/buiness/coinfo/add',
    method: 'post',
    data: params
  })
}
// 编辑项目标段列表
export function editInfo (params) {
  return request({
    url: '/buiness/coinfo',
    method: 'put',
    data: params
  })
}
// 登录状态下查询地区信息列表
export function getArea(data) {
  return request({
    url: '/buiness/place/getplaceList',
    method: 'post',
    data: data
  })
}
// 未登录状态下查询地区信息列表
export function getAreaList(data) {
  return request({
    url: '/buiness/place/getPlaceListNotToken',
    method: 'post',
    data: data
  })
}
// 获取账号认证状态
export function getAccountState(data) {
  return request({
    url: '/buiness/coinfo/ckCoInfo',
    method: 'post',
    data: data
  })
}
//立即支付
export function pay(data) {
  return request({
    url: '/egs/pay/payForOrder',
    method: 'post',
    params: data
  })
}
//删除文件
export function delImg(data) {
  return request({
    url: '/buiness/coword/remove',
    method: 'post',
    data: data
  })
}
//获取公司列表
export function getCoList(userId) {
  return request({
    url: '/buiness/coinfo/getList/'+userId,
    method: 'get'
  })
}
//获取公司列表信息
export function getCoInfo(id) {
  return request({
    url: '/buiness/coinfo/changeCo/'+id,
    method: 'get'
  })
}
//申请发票
export function ckInvoice(data) {
  return request({
    url: '/buiness/gat/ckInvoice/',
    method: 'get',
    params:data
  })
}
//解绑
export function unBinding(data) {
  return request({
    url: '/buiness/coinfo/ckPhone',
    method: 'post',
    data: data
  })
}
//绑定
export function binding(data) {
  return request({
    url: '/buiness/coinfo/unbindPhoneCoId',
    method: 'post',
    data: data
  })
}
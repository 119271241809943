<template>
  <div class="attestation-containter">
    <nav-menu></nav-menu>
    <div class="attestation-body">
      <div class="containter flex">
        <div class="help-left">
          <div :class="tab == 'basic' ? 'active' : ''" @click="changeTab('basic')">基本信息</div>
          <div :class="tab == 'order' ? 'active' : ''" @click="changeTab('order')">我的订单</div>
          <div :class="tab == 'bill' ? 'active' : ''" @click="changeTab('bill')">我的发票</div>
        </div>
        <div class="basic-info" style="width:80%;padding-bottom: 20px;" v-if="tab == 'basic'">
          <div class="attestation-steps flex">
            <div>账号认证</div>
            <el-button type="success" size="small" @click="addCoForm">新增</el-button>
          </div>
          <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick" v-if="coList.length > 0">
              <el-tab-pane :label="item.coName" :name="item.id + ''" v-for="item in coList" :key="item.id">
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="attestation-form">
            <div class="state-img">
              <img v-if="form.state == 0" src="./../assets/cs.png" alt="" />
              <img v-if="form.state == 1" src="./../assets/fs.png" alt="" />
              <img v-if="form.state == 2" src="./../assets/cs_nopass.png" alt="" />
              <img v-if="form.state == 3" src="./../assets/fs_nopass.png" alt="" />
              <img v-if="form.state == 4" src="./../assets/succ.png" alt="" />
            </div>
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm"
              :disabled="disable">
              <el-form-item label="电子营业执照：" prop="avatar" class="file-upload">
                <el-upload class="avatar-uploader" :action="action" :show-file-list="false"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-error="uploadError"
                  :headers="headers" :disabled="imageUrl != '' && imageUrl != null">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div style="position:relative">
                  <div v-if="imageUrl"
                    style="margin-left:10px;color:#409EFF;position:absolute;bottom:40px;white-space:nowrap">
                    {{ fileName }}<i class="el-icon-circle-close" style="margin-left:5px" @click="removeAvatar"></i>
                  </div>
                  <div style="position:absolute;bottom:10px;white-space:nowrap">（除png、jpg、gif格式外的文件无法预览！）</div>
                </div>
              </el-form-item>
              <el-form-item label="企业名称：" prop="coName">
                <el-input v-model="form.coName" placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <!-- <el-form-item label="企业所在地：" prop="coAddress">
                <el-cascader v-model="form.coAddress" :options="options"
                  :props="{ children: 'childList', value: 'name', label: 'name' }" clearable>
                </el-cascader>
              </el-form-item> -->
              <el-form-item label="企业所在地：" prop="coAddress">
                <el-input v-model="form.coAddress" placeholder="请输入企业所在地"></el-input>
              </el-form-item>
              <el-form-item label="社会统一信用代码：" prop="coCode">
                <el-input v-model="form.coCode" placeholder="请输入社会统一信用代码"></el-input>
              </el-form-item>
              <el-form-item label="企业法人：" prop="coLep">
                <el-input v-model="form.coLep" placeholder="请输入企业法人"></el-input>
              </el-form-item>
              <el-form-item label="法人身份证：" prop="coLepCode">
                <el-input v-model="form.coLepCode" placeholder="请输入法人身份证"></el-input>
              </el-form-item>
              <el-form-item label="联系人：" prop="contName">
                <el-input v-model="form.contName" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系方式：" prop="contPhone">
                <el-input v-model="form.contPhone" placeholder="请输入联系方式"></el-input>
              </el-form-item>
              <el-form-item label="联系人职务：" prop="contPost">
                <el-input v-model="form.contPost" placeholder="请输入联系人职务"></el-input>
              </el-form-item>
              <el-form-item label="上传授权书：" prop="word" class="upload-file">
                <div>
                  <el-upload class="upload-demo" :action="action" :headers="headers" :limit="limit"
                    :on-success="handleWordSuccess" :on-remove="removeFile" :file-list="fileList">
                    <!-- <el-button size="small" type="primary">点击上传</el-button> -->
                    <i v-if="!(fileList[0] && fileList[0].url)" style="border: 1px dashed #d9d9d9; width: 50px;"
                      class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <span v-if="!(fileList[0] && fileList[0].url)"><a
                      href="https://www.ygctszfw.com/up/uploadPath/数据服务系统信息传输授权书.doc"><i class="el-icon-download"></i>
                      授权书模板.doc</a></span>
                </div>
              </el-form-item>
            </el-form>
            <div class="attestation-form-btn">
              <el-button type="success" @click="edit" style="margin-right:10px"
                v-if="(form.state == '4' || form.state == '2' || form.state == '3') && !editStatus">重新编辑
              </el-button>
              <el-button type="primary" v-if="editStatus || !form.state" @click="submit('ruleForm')">提交</el-button>
              <el-button type="info" style="margin-left:10px" v-if="editStatus && form.state" @click="reset">取消
              </el-button>
              <el-button v-if="form.state == 4" type="warning" style="margin-left:10px" @click="unbinding">解绑</el-button>
            </div>
          </div>
        </div>
        <div class="order-box" v-if="tab == 'order'">
          <div class="order-title">我的订单</div>
          <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick" v-if="coList.length > 0">
              <el-tab-pane :label="item.coName" :name="item.id + ''" v-for="item in coList" :key="item.id">
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="order-tabs flex-a flex-b">
            <div :class="type == item.status ? 'order-tab active' : 'order-tab'" v-for="item in tabs" :key="item.id"
              @click="changeType(item.status)">{{ item.title }}</div>
            <div class="order-search flex-a">
              <el-input v-model="keyword" placeholder="请输入搜索关键字" style="width: 50%;" @keyup.enter.native="search">
              </el-input>
              <div class="btn" @click="search">查询</div>
            </div>
          </div>
          <div class="order-list">
            <div class="order-item" v-for="(item, index) in list" :key="index">
              <div class="order-item-title flex-a flex-b">
                <div class="order-item-title-tag flex-a">
                  <span class="order-item-name">{{ item.tendName }}</span>
                  <el-tag size="small" type="primary"
                    v-if="(item.type == '0' || item.type == '1') && item.status == '0' && item.state == '0'">待审批
                  </el-tag>
                  <el-tag size="small" v-if="item.type == '1' && item.state == '3'"
                    :type="item.status == '0' ? 'primary' : item.status == 'warning' ? '' : item.status == '2' ? 'info' : item.status == '3' ? 'success' : ''">
                    {{ item.status == '0' ? '申请中' : item.status == '1' ? '已申请' : item.status == '2' ? '已取消' :
                        item.status
                          ==
                          '3' ? '已完成' : ''
                    }}</el-tag>
                  <el-tag size="small" v-if="item.status == '1' && item.state != '3'"
                    :type="item.state == '0' ? 'primary' : item.state == 'warning' ? '' : item.state == '2' ? 'info' : item.state == '3' ? 'success' : ''">
                    {{ item.state == '0' ? '待缴费' : item.state == '1' ? '未缴费' : item.state == '2' ? '已超时' :
                        item.state
                          ==
                          '3' ? '已缴费' : ''
                    }}</el-tag>
                  <el-tag size="small" type="danger" v-if="item.type != '1' && item.type != '0'">
                    {{ item.type == '2' && item.status != '3' ? '退保中' : item.type == '2' && item.status == '3' ? '已退保' :
                        item.type
                          == '3' && item.status != '3' ? '理赔中' : item.type == '3' && item.status == '3' ? '已理赔' : ''
                    }}</el-tag>
                  <el-tag size="small" type="danger" v-if="item.status == '2'">
                    {{ item.type == '2' && item.status == '2' ? '退保被驳回' : item.type == '1' && item.status == '2' ?
                        '订单被取消' :
                        item.type == '3' && item.status == '2' ? '理赔被驳回' : ''
                    }}</el-tag>
                  <el-tag size="small" :type="item.invoice != '1' ? 'success' : 'primary'" v-if="item.invoice != '1'">
                    {{ item.invoice != '1' ? '发票申请中' : '发票已申请' }}</el-tag>
                </div>
                <div class="order-item-title-btns">
                  <!-- <span class="btn-plain">评价</span> -->
                  <span v-if="item.gatNo !== null" class="btn-plain" @click="toDetail(item.orderNo)">查看</span>
                  <span class="btn-plain"
                    v-if="item.type != '2' && item.type != '3' && !(item.status == '1' && (item.state == '0' || item.state == '1')) && !(item.type == '1' && item.status == '2') && new Date().getTime() < new Date(item.prjEndEarDate).getTime()"
                    @click="surrender('2', item.orderId)">申请退保</span>
                  <span class="btn-plain"
                    v-if="item.type != '2' && item.type != '3' && !(item.status == '1' && (item.state == '0' || item.state == '1')) && !(item.type == '1' && item.status == '2') && new Date().getTime() < new Date(item.prjEndEarDate).getTime()"
                    @click="surrender('3', item.orderId)">申请理赔</span>
                  <span class="btn-plain" v-if="item.type == '2' && item.status == '2'"
                    @click="surrender('2', item.orderId)">重新申请退保</span>
                  <span class="btn-plain" v-if="item.type == '2' && item.status == '2'"
                    @click="reCkType('2', item.orderId)">撤销退保</span>
                  <span class="btn-plain" v-if="item.type == '3' && item.status == '2'"
                    @click="surrender('3', item.orderId)">重新申请理赔</span>
                  <span class="btn-plain" v-if="item.type == '3' && item.status == '2'"
                    @click="reCkType('2', item.orderId)">撤销理赔</span>
                  <span class="btn-plain" v-if="item.type != '2' && item.type != '3' && item.state == '3'"
                    @click="applyLetter(item)">申请发票</span>
                </div>
              </div>
              <div class="order-item-body">
                <div>标段编号：{{ item.tendNo }}</div>
                <div>保函编号：{{ item.gatNo }}</div>
                <div>保证金金额：{{ item.prjEarMoney }}</div>
                <div>订单类型：{{ item.dbType }}</div>
                <div>申请时间：{{ item.createdAt }}</div>
                <div>开标时间：{{ item.tendStDate }}</div>
              </div>
              <!-- <div class="order-item-btn btn-plain" @click="toDetail">查看</div> -->
            </div>
            <div class="order-page" v-if="list.length > 0">
              <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="list.length == 0" style="text-align:center;margin-top:40px;color:#666">
            <img src="./../assets/noData.png" style="width:200px;" alt="">
            <p>暂无数据</p>
          </div>
        </div>
        <div class="bill-box" style="margin-top:20px;" v-if="tab == 'bill'">
          <div class="order-title">我的发票</div>
          <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick" v-if="coList.length > 0">
              <el-tab-pane :label="item.coName" :name="item.id + ''" v-for="item in coList" :key="item.id">
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="order-tabs flex-a">
            <!-- <div :class="'' == invoice ? 'order-tab active' : 'order-tab'" @click="changeInvoice('')">全部</div> -->
            <div :class="'2' == invoice ? 'order-tab active' : 'order-tab'" @click="changeInvoice('2')">已申请</div>
            <div :class="'3' == invoice ? 'order-tab active' : 'order-tab'" @click="changeInvoice('3')">已开票</div>
          </div>
          <div class="order-list" style="margin-top:20px">
            <div class="order-item" v-for="(item, index) in list" :key="index">
              <div class="order-item-title flex-a flex-b">
                <div class="order-item-title-tag flex-a">
                  <span class="order-item-name">订单编号：{{ item.tendName }}</span>
                  <el-tooltip class="item" effect="dark" :content="item.invoice != '1' ? '请联系客户经理处理' : '请联系客户经理获取发票'"
                    placement="top">
                    <el-tag size="small" :type="item.invoice != '1' ? 'success' : 'primary'" v-if="item.invoice != '1'">
                      {{ item.invoice != '1' ? '发票申请中' : '发票已申请' }}</el-tag>
                  </el-tooltip>
                </div>
                <div class="order-item-title-btns">
                  <span class="btn-plain" v-if="item.invoice == '3' && item.invoiceUrl != null"><a
                      :href="item.invoiceUrl">下载发票</a></span>
                </div>
              </div>
              <div class="order-item-body">
                <div>项目名称：{{ item.tendNo }}</div>
                <div>保函编号：{{ item.gatNo }}</div>
                <div>保费金额：{{ item.prjEarMoney }}</div>
                <div>申请时间：{{ item.createdAt }}</div>
              </div>
              <!-- <div class="order-item-btn btn-plain" @click="toDetail">查看</div> -->
            </div>
            <div class="order-page" v-if="list.length > 0">
              <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
          <div v-if="list.length == 0" style="text-align:center;margin-top:40px;color:#666">
            <img src="./../assets/noData.png" style="width:200px;" alt="">
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
    <pay-test-modal ref="payTestModal"></pay-test-modal>
    <foot></foot>
    <!-- 解绑弹窗 -->
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="25%" center class="unbind-dialog">
      <el-form :model="unbindForm" :rules="unbindRules" ref="unbindForm" label-width="80px">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="unbindForm.phone" :readonly="readonly" autocomplete="off" placeholder="请输入绑定的手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" class="get-code">
          <el-input v-model="unbindForm.code" autocomplete="off" placeholder="请输入验证码"
            style="width: 50%;margin-right: 3%;"></el-input>
          <el-button v-show="countshow" size="mini" style="font-size: 13px;" @click="getcode">获取验证码</el-button>
          <el-button v-show="!countshow" size="mini" style="font-size: 13px;" disabled>{{ count
          }}
            s</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUnbind">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import JSEncrypt from './../lib/utils/jsencrypt'
import baseUrl from '@/lib/utils/baseUrl'
import { addInfo, getArea, getAccountState, getAreaList, editInfo, pay, delImg, getCoList, getCoInfo, ckInvoice, unBinding, binding } from '@/lib/api/attestation'
import { getOrderList, postCkType, reCkType } from '@/lib/api/order'
import { getNoCode, getCode } from '../lib/api/project'
import PayTestModal from './PayTestModal' // 二维码对话框组件
export default {
  name: 'attestation',
  components: { PayTestModal },
  data() {
    return {
      dialogFormVisible: false,
      unbindForm: {
        phone: localStorage.getItem('nickName'),
        code: ''
      },
      unbindRules: {
        phone: [
          { required: true, message: '请填写绑定的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写验证码', trigger: 'blur' }
        ]
      },
      t: 60,
      timer: null,
      countshow: true,
      count: '',
      fileName: '',
      activeName: '',
      coList: [],
      invoice: '2',
      headers: { Authorization: "Bearer " + localStorage.getItem('token') },
      limit: 1,
      tab: 'basic',
      hasFmt: false,
      editStatus: false,
      disable: false,
      step: 1,
      action: baseUrl + '/buiness/coword/uploadFile',
      imageUrl: '',
      fileList: [],
      form: {
        coName: '',
        coAddress: '',
        coCode: '',
        coLep: '',
        coLepCode: '',
        contName: '',
        contPhone: '',
        contPost: ''
      },
      rules: {
        avatar: [
          {
            required: true, validator: (rule, value, callback) => { // 图片验证
              if (!this.hasFmt) {
                callback(new Error('请上传图片'));
              } else {
                callback();
              }
            }, message: '请上传营业执照', trigger: 'change'
          }
        ],
        coName: [
          { required: true, message: '请填写企业名称', trigger: 'blur' }
        ],
        coAddress: [
          { required: true, message: '请填写企业所在地', trigger: 'blur' }
        ],
        coCode: [
          { required: true, message: '请填写社会统一信用代码', trigger: 'blur' }
        ],
        coLep: [
          { required: true, message: '请填写企业法人', trigger: 'blur' }
        ],
        coLepCode: [
          { required: true, message: '请填写法人身份证', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证格式不正确' }
        ],
        contName: [
          { required: true, message: '请填写联系人', trigger: 'blur' }
        ],
        contPhone: [
          { required: true, message: '请填写联系方式', trigger: 'blur' },
          { pattern: /^1(3|4|5|7|8)\d{9}$/, message: '手机号格式不正确' }
        ],
        contPost: [
          { required: true, message: '请填写联系人职务', trigger: 'blur' }
        ],
        word: [
          {
            required: true, validator: (rule, value, callback) => {
              if (!(this.fileList[0] && this.fileList[0].url)) {
                callback(new Error('请上传附件'));
              } else {
                callback();
              }
            }, message: '请上传附件', trigger: 'change'
          }
        ],
      },
      options: [],
      type: '',
      total: 0,
      keyword: '',
      tabs: [
        {
          status: '',
          title: '全部'
        },
        {
          status: '0',
          title: '申请中'
        },
        {
          status: '1',
          title: '已申请'
        },
        {
          status: '2',
          title: '已取消'
        },
      ],
      list: [],
      title: '',
      readonly:false
    }
  },
  created() {
    this.getAccountState()
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab
      this.list = []
      if (this.$route.query.tab == 'order' || this.$route.query.tab == 'bill') {
        this.getList({ orderNo: 'null', status: this.type, pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
      }
    }
  },
  methods: {
    //申请退保-理赔
    surrender(type, orderId) {
      this.$prompt('请输入理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        postCkType({ type, orderId, value }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.getList({ orderNo: 'null', status: '', pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {
      });
    },
    //撤销退保--理赔
    reCkType(type, orderId) {
      this.$confirm(type == '2' ? '确定撤销退保?' : '确定撤销理赔?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        reCkType({ type, orderId }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.getList({ orderNo: 'null', status: '', pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {
      });
    },
    //申请发票
    applyLetter(item) {
      if (item.invoice != '1') {
        this.$message({
          message: '已申请发票，请到我的发票中查看！',
          type: 'warning'
        });
      } else {
        this.$confirm('确定申请发票?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          ckInvoice({ orderId: item.orderId }).then(res => {
            if (res.code == 200) {
              this.$message({
                message: '申请成功，请联系客户经理！',
                type: 'success'
              });
              this.getList({ orderNo: 'null', status: '', pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
            } else {
              this.$message.error(res.msg);
            }
          })
        }).catch(() => {
        });
      }
    },
    //查询
    search() {
      this.getList({ orderNo: 'null', status: this.tab, pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
    },
    //获取公司列表
    getCoList() {
      getCoList(localStorage.getItem('userId')).then(res => {
        if (res.code == 200) {
          this.coList = res.data.list
          this.activeName = res.data.id + ''
          this.getCoInfo(res.data.id + '')
        }
      })
    },
    handleClick() {
      this.editStatus = false
      this.getCoInfo(this.activeName)
      this.coList.forEach(item => {
        if (item.id == this.activeName) {
          this.coName = item.coName
        }
      })
      this.$message({
        message: `已切换到${this.coName}`,
        type: 'success'
      });
    },
    // 获取公司列表信息
    getCoInfo(id) {
      getCoInfo(id).then(res => {
        if (res.code == 200) {
          if (res.code == 200 && res.data && res.data.id) {
            this.form = res.data
            // this.$refs['ruleForm'].clearValidate();
            this.imageUrl = res.data.url
            this.fileName = res.data.url ? res.data.url.split('//')[1].split('/')[res.data.url.split('//')[1].split('/').length - 1] : ''
            this.hasFmt = true
            this.fileList = res.data.wordUrl != null ? [{ name: res.data.wordName, url: res.data.wordUrl }] : []
            this.disable = true
            this.getList({ orderNo: 'null', status: this.type, pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
          }
          else if (!res.data) {
            this.editStatus = true
          }
        }
      })
    },
    //新增
    addCoForm() {
      this.activeName = ''
      this.disable = false
      this.form = {}
      this.imageUrl = ''
      this.fileList = []
      this.hasFmt = false
      this.fileName = ''
      this.editStatus = true
    },
    //获取订单列表
    getList(params) {
      getOrderList(params).then(res => {
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        } else if (res.code == 401) {
          this.$alert('请先登录后再查看!', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.$router.push('/')
            }
          });
        }
      })
    },
    //tab切换
    changeType(index) {
      this.type = index
      this.getList({ orderNo: 'null', status: this.type, pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
    },
    //发票切换
    changeInvoice(index) {
      this.invoice = index
      this.getList({ orderNo: 'null', invoice: this.invoice, pageSize: 10, pageNum: 1, name: this.keyword })//获取发票列表
    },
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getList({ orderNo: 'null', status: this.type, pageSize: 10, pageNum: val, name: this.keyword })//获取订单列表
    },
    //查看详情
    toDetail(orderNo) {
      this.$router.push({ path: '/orderDetail', query: { orderNo: orderNo } })
    },
    //支付
    toPay(orderId) {
      pay(
        {
          wayCode: 'WX_NATIVE',
          id: orderId,
        }
      ).then(res => {
        this.$refs.payTestModal.showModal('WX_NATIVE', res.data) // 打开弹窗
      })
    },
    //获取账号认证状态
    getAccountState() {
      getAccountState().then(res => {
        if (res.code == 200 && res.data && res.data.id) {
          this.form = res.data
          // this.form.coAddress = this.form.coAddress.split('/')
          this.imageUrl = res.data.url
          this.fileList = res.data.wordUrl != null ? [{ name: res.data.wordName, url: res.data.wordUrl }] : []
          this.disable = true
        }
        if (res.code == 200) {
          this.getCoList()
        } else if (!res.data) {
          this.editStatus = true
        }
      })
    },
    //删除文件
    removeFile(file, fileList) {
      if (this.form.state != 4 && this.editStatus) {
        delImg({ url: file.url }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '删除成功！',
              type: 'success'
            });
            this.fileList = []
          }
        })
      } else {
        this.fileList = [{ name: file.name, url: file.url }]
        this.$message({
          message: '账号信息已认证，无法删除！',
          type: 'warning'
        });
      }
    },
    //取消
    reset() {
      this.editStatus = false
      this.disable = true
    },
    //编辑
    edit() {
      this.editStatus = true
      this.disable = false
    },
    //基本信息--我的订单
    changeTab(txt) {
      this.tab = txt
      this.list = []
      if (txt == 'order') {
        this.getList({ orderNo: 'null', status: this.type, pageSize: 10, pageNum: 1, name: this.keyword })//获取订单列表
      } else if (txt == 'bill') {
        this.getList({ orderNo: 'null', invoice: this.invoice, pageSize: 10, pageNum: 1, name: this.keyword })//获取发票列表
      }
    },
    //获取地址列表信息
    getAreaList() {
      getArea().then(res => {
        if (res.code == 200) {
          this.options = res.data
        }
      })
    },
    //解绑
    unbinding() {
      this.title = '解绑：' + this.form.coName
      this.dialogFormVisible = true
      this.readonly=true
      this.$refs['unbindForm'].resetFields();
    },
    //提交解绑
    submitUnbind() {
      this.$refs['unbindForm'].validate((valid) => {
        if (valid) {
          if (this.title == '绑定') {
            binding(Object.assign({}, this.unbindForm, { id: this.form.id })).then(res => {
              if (res.code == 200) {
                this.dialogFormVisible = false
                this.$message({
                  type: 'success',
                  message: res.msg
                });
                this.$router.go(0)
              }
            })
          } else {
            unBinding(this.unbindForm).then(res => {
              if (res.code == 200) {
                this.dialogFormVisible = false
                this.$confirm('是否继续绑定新的手机号?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.title = '绑定'
                  this.count = 0;
                  this.countshow = true;
                  this.timer = null
                  this.readonly=false
                  this.unbindForm.phone=''
                  this.unbindForm.code=''
                  this.dialogFormVisible = true
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消绑定'
                  });
                });
              }
            })
          }
        }
      });
    },
    // 获取验证码
    async getcode() {
      if (this.unbindForm.phone == '') {
        this.$message({
          message: '请先填写手机号',
          type: 'warning'
        });
      } else {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.countshow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.countshow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
        this.codesuccess() // 调用接口获取验证码
      }
    },
    codesuccess() {
      this.unbindForm.code = ''
      getCode({ phone: this.unbindForm.phone }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '已发送',
            type: 'success'
          });
        }
      })
    },
    // 上次营业执照前
    beforeAvatarUpload(file) {
      this.hasFmt = true
    },
    //营业执照上传成功
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.imageUrl = res.url;
        this.hasFmt = true
        this.fileName = res.url.split('//')[1].split('/')[res.url.split('//')[1].split('/').length - 1]
        console.log(this.hasFmt)
      }
    },
    //上传失败
    uploadError(err) {
      this.$message.error(err);
    },
    //删除营业执照
    removeAvatar() {
      if (this.form.state != 4 && this.editStatus) {
        delImg({ url: this.imageUrl }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '删除成功！',
              type: 'success'
            });
            this.hasFmt = false
            this.imageUrl = ''
            this.fileName = ''
          }
        })
      } else {
        this.$message({
          message: '账号信息已认证，无法删除！',
          type: 'warning'
        });
      }
    },
    //附件上传
    handleWordSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.fileList.push({ name: file.name, url: res.url })
        console.log(this.fileList)
      }
    },
    //营业执照上传前
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error('上传图片格式不正确!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!');
      }
      return isJPG && isLt2M;
    },
    //提交
    submit(formName) {
      var that = this
      if (!that.form.state) {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            // that.form.coAddress = that.form.coAddress.join('/')
            var param = Object.assign({}, that.form, { url: that.imageUrl, wordUrl: that.fileList[0].url })
            addInfo(param).then(res => {
              if (res.code == 200) {
                that.$store.commit('isCheck', res.data)
                that.$message({
                  message: '提交成功！',
                  type: 'success'
                });
                that.getAccountState()
                that.getCoList()
              }
            })
          } else {
            return false;
          }
        });
      } else if (that.form.state == '4' || that.form.state == '2' || that.form.state == '3') {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            var param = Object.assign({}, that.form, { url: that.imageUrl, wordUrl: that.fileList[0].url })
            editInfo(param).then(res => {
              console.log(param)
              if (res.code == 200) {
                that.$store.commit('isCheck', res.data)
                that.$message({
                  message: '提交成功！',
                  type: 'success'
                });
                that.editStatus = false
                that.getCoInfo(that.activeName)
              }
            })
          } else {
            return false;
          }
        });
      }
    },
  }
}
</script>
<style scoped lang="less">
.attestation-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .attestation-body {
    padding: 20px 0;

    .containter {
      padding: 0;

      .help-left {
        background: #F5F6F8;
        width: 200px;
        min-height: 90vh;
        border-radius: 5px;
        box-sizing: border-box;

        div {
          text-align: center;
          height: 60px;
          line-height: 60px;
          color: #333;
        }

        div.active {
          background: #0C5BBB;
          color: #fff;
        }

        div:hover {
          cursor: pointer;
        }
      }

      .attestation-steps {
        padding: 20px 0 50px 50px;

        div {
          color: #1890FF;
          font-size: 20px;
          font-weight: bold;
          width: 10%;
          white-space: nowrap;
        }

        .el-steps {
          width: 100%;
        }
      }

      .attestation-form {
        width: 60%;
        margin: 50px auto 0;
        position: relative;

        .state-img {
          img {
            width: 150px;
            position: absolute;
            top: -20px;
            right: -100px;
          }
        }

        .unbinding {
          position: absolute;
          left: -130px;
          top: 0px;
        }

        .attestation-form-btn {
          text-align: center;

          div {
            margin: 40px auto 0;
            width: 120px;
            font-size: 16px;
          }

          .el-button {
            margin: 40px auto 0;
          }
        }
      }

      .tabs {
        padding-left: 50px;
        text-align: center;
      }
    }
  }
}

.order-box,
.bill-box {
  width: 80%;
  padding-left: 10px;

  .order-title {
    color: #1890FF;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 0 30px 40px;
  }

  .order-tabs {
    padding-left: 40px;
    margin-top: 20px;

    .order-tab {
      border: 1px solid #D9D9D9;
      border-radius: 5px;
      margin-right: 10px;
      color: #5A5A5A;
      padding: 5px 12px;
      font-size: 14px;
      white-space: nowrap;
    }

    .order-tab.active {
      color: #fff;
      background: #1890FF;
      border-color: #1890FF;
    }

    .order-tab:hover {
      cursor: pointer;
    }
  }

  .order-search {
    margin: 20px auto;
    width: 70%;

    .btn {
      margin-left: 20px;
      letter-spacing: 3px;
    }
  }

  .order-list {
    padding: 0 10px;

    .order-item {
      border-radius: 5px;
      padding: 20px 60px 10px 50px;
      box-shadow: 0 0 10px #E6E6E6;
      margin-bottom: 15px;
      position: relative;

      .order-item-title {
        color: #000;
        font-size: 18px;
        margin-bottom: 15px;
        white-space: nowrap;

        .order-item-title-tag {
          width: 60%;

          span {
            margin-right: 10px;
          }

          .order-item-name {
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
        }

        .order-item-title-btns {
          span {
            border-color: #157EDF;
            color: #1890FF;
            padding: 3px 8px;
            border-radius: 10px;
            margin-left: 15px;
          }
        }
      }

      .order-item-body {
        div {
          display: inline-block;
          width: 45%;
          margin-bottom: 10px;
          font-size: 14px;
          color: #5A5A5A;
        }
      }

      .order-item-btn {
        border-color: #157EDF;
        color: #1890FF;
        width: 60px;
        position: absolute;
        top: 50%;
        right: 60px;
        transform: translateY(-50%);
      }
    }

    .order-page {
      text-align: center;
    }
  }
}

.upload-file {
  position: relative;

  span {
    position: absolute;
    right: 40%;
    top: 50%;
    transform: translateY(-50%);

    a {
      color: #1890FF;
    }
  }

  span:hover {
    cursor: pointer;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

.upload-demo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
